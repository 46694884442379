<template>
  <div style="text-align:justify;text-indent:30px">
    <p>Welcome to the https://www.trafficmanager.com website (the "Website"). These are the Terms and Conditions ("Terms and Conditions") of use applicable to your access and use of our Website and the services made available through the Website (collectively, the "Services")</p>
    <p>These Terms and Conditions must be followed by any individual using the site as a visitor (the "Visitor") without registration, including accessing the platform we have installed online as a product demo ("the Demo") or registered as a User (individuals/businesses) (the "User") and are the Terms and Conditions under which TrafficManager ("TrafficManager,” "we,” "us,” "our") provides you access to the Website or Services.</p>
    <p>You must carefully read these Terms and Conditions before registering on our Website or using our Services. By registering to our Website or using the Services you agree to accept and comply the Terms and Conditions, our Privacy Policy, Client Agreement or any other rules and conditions provided on our Website and all applicable laws.</p>
    <p>TrafficManager may change these Terms and Conditions in whole or in portion at any time without further notice and you agree that you will be bounded by any changes to this Terms and Conditions that will be posted on the Website. The date of the last revision of the Terms and Condition is included ad the bottom of this page. Any changes to the Terms and Conditions published on the Site will be immediately applicable to Users and Visitors.</p>
    <p>You understand and agree that TrafficManager may discontinue or restrict your use of the Website and/or Service for any reason or no reason with or without notice.</p>
    <p>YOU ACKNOWLEDGE THAT YOUR USE OF THE WEBSITE AND/OR THE SERVICES SIGNIFIES THAT YOU AGREE TO THIS TERMS AND CONDITIONS AND CONSTITUTES YOUR BINDING ACCEPTANCE OF THIS TERMS OF CONDITIONS, INCLUDING ANY MODIFICATIONS THAT TRAFFICMANAGER MAKES FROM TIME TO TIME.</p>
    <p>Definitions:</p>
    <ol type="a">
      <li> TrafficManager: represents the company Traffic Manager Limited has registered address 3, Advance Business Centre, Triq G. Flores, Santa Venera, SVR1950, Malta, registration number C76756 under the laws of Malta and/or Traffic Manager Group S.R.L. with registered address in Str. Ionel Teodoreanu 1 nr. 1 bl. 1 ap. 1 500165 Brașov, Romania, registration number J08/1846/2020, or other 3rd party entities who have the right to provide our clients access to the services via our Website and collect the fees form the Clients ("we,” "us,” "our"); </li>
      <li> Website: represents the Website located at the address https://www.trafficmanager.com; </li>
      <li> Demo: is the TrafficManager platform/software technology demonstration, available on our Website as sample for the visitors who might be interested to try the product or see its features before buying. </li>
      <li> Terms and Conditions: represents the Terms and Conditions on this page with their appendices and all the further and subsequent supplements and amendments. </li>
      <li> Privacy Policy: the document setting out how the data from the User or Visitor is collected, used, retained and transferred. This includes personal data and other important information. </li>
      <li> Client Agreement: represents the agreement between TrafficManager (or other TrafficManager licensee) and the User / Client / Customer which grand the User right to access and use our Services through the Website; </li>
      <li> Services: represent all the services we are providing to the clients via our Website, including access to the client dashboard, affiliate tracking platform, and all the upgrades, new features developed, modifications, updates, or personal customization developed in whole or partially by us; </li>
      <li> Platform: the TrafficManager affiliate tracking software/technology provided to our clients, including all the updates, new features, customizations, server installation and maintenance, updates and monitoring developed in whole or partially by us; </li>
      <li> Visitor: any person or business/company visiting our Website, subject to our Terms and Conditions and our Privacy Policy but not registered in our database; </li>
      <li> User: any person or business/company who, after accepting our Terms and Conditions, Privacy Policy and our Client Agreement, registered and created a user account to use our Services through the Website ("client", "customer", "you", "your"); </li>
      <li> User Account: the account resulted after a user registration on our Website that contains personal/company data including payment info with the purpose to use our Services via the Website; </li>
      <li> Affiliate: represent any private or business/company providing advertisements via their own websites or other promotion channels for a product or service owner ("Advertiser"). </li>
      <li> Advertiser: represents a private or business/company that owns one or multiple products or a service, physical or online, looking for advertisement with the purpose of selling the products or services. </li>
    </ol>
    <p class="font-weight-bold">
      1. Requirements
    </p>
    <p> Before you can use our Services, you must agree to this Terms and Conditions and acknowledge that you have read our Privacy Policy, Client Agreement or any other rules and conditions provided on our Website which you will be deemed to have done by utilizing the Services. </p>
    <p>In order to access our Services, you will need to register on our Website and provide complete, up-to-date information about you and/or your company. Once your account is created, you will receive a password that you will need to use, together with the email you used to register to access the Services via our Website.</p>
    <p>To complete the registration, you will need to electronically accept our Client Agreement so we can continue providing you access to our Services, and at the end of the trial period (if you are eligible to a free trial period) you will need to pay your Pro Forma Invoices, according to the plan you chose as detailed in our Client Agreement.</p>
    <p>These requirements may change as the Services evolves.</p>
    <p class="font-weight-bold">
      2. Use of the Services and Restrictions
    </p>
    <ol type="a">
      <li> You will have the right to access our Platform, upload, store, analyze, optimize and download your data from the Software; </li>
      <li> While using our Platform and Services you agree that you will not violate any applicable law and regulations, and you agree to accept and comply the Terms and Conditions, our Privacy Policy, the Client Agreement and/or any other rules and conditions provided on our Website; </li>
      <li> You agree not to license, transfer, sell or re-sell any information, content or service obtained from our Website; </li>
      <li> It is forbidden to make available, through the Website and/or our Services, any material or information that infringes any patent, trade secret, copyright, trademark, or any other right of any party (including the rights of publicity or privacy); </li>
      <li> Notwithstanding anything to the contrary, you may not: (i) make any misleading, false or deceptive statement representation regarding TrafficManager and/or the Services, (ii) sell, rent, lease, assign, act as a services provider, or grant rights in the Services, including, without limitation sublicense, to any other entity without the prior written consent of TrafficManager, (iii) remove any proprietary notices from the Services, (iv) permit, cause or authorize the modification, creation of derivative works, reverse engineering, translation, decompiling, disassembling or hacking of the Services. </li>
      <li> You agree that you will not use our Website or the Services for any commercial purpose or the benefit of any 3rd party or charge any person, or receive any compensation for, the use of our Website or Services. </li>
      <li> You may not connect to or use our Website and/or Services in any way not expressly permitted by this Terms and Conditions, Privacy Policy, Client Agreement, or any other rules and conditions provided on our Website. </li>
    </ol>
    <p class="font-weight-bold">
      3. Communications
    </p>
    <p> TrafficManager has the right at any time to publish on his Website or Platform any proposal to new services or other offers/proposals or send to the Users messages via email in the email address provided at the time of the registration such offers about our Services or any other relevant matters that could provide a better user experience to the end Clients. These messages may include invitations, suggestions, reminders, or descriptions of future or existing services or other important notices, but also promotional or marketing content. </p>
    <p class="font-weight-bold">
      4. Customer Support
    </p>
    <p>The User will receive different type of support level according to the Subscription plan he choose. The most common support offered will be via the support request forms available in the Website, this include any kind of technical support, updates or modifications of the Website or Services, consultation or advise about the use of Website and Services.</p>
    <p class="font-weight-bold">
      5. Payments of the Services
    </p>
    <p>According to the conditions written in the Client Agreement, TrafficManager has the right to collect the fees for the Services from the User / Client. We may use various billing service providers to collect the fees from the User. The User, by using the billing provider service to pay our fee, agrees to be subject to and comply with the terms and conditions, privacy policies or any other other rules and conditions provided by the billing provider service and all applicable laws. All the invoice issuing terms and payment conditions are indicated in the Client Agreement. TrafficManager shall not be responsible and/or liable of any acts of omissions of billing service providers.</p>
    <p class="font-weight-bold">
      6. Rights and Responsibilities of the Users and Visitors
    </p>
    <ol>
      <li> All the Users / Clients and Visitors of the TrafficManager Website and Services agree and acknowledge that TrafficManager even if it has the right, is not required to verify, so TrafficManager cannot be held responsible for any Visitor or User behaviur, action or inaction related to our Website and/or Services on their compliance with any applicable law. </li>
      <li> When registering to the Website or Services, the User undertakes to provide only true information about his/her real name and company he/she represent including the contact details and to promptly update (if later needed) any information on the Website that becomes irrelevant, outdated, or inapplicable and to not create more than one Account in our system (One account per user allowed only). </li>
      <li> The User agrees that he/she is the only responsible for maintaining the confidentiality of their password and account, if any, and is fully responsible for any and all activities that occur under their password or account. The User agrees that he/she needs to ensure that they log out from their account at the end of each session when accessing the Website or Services. In case of any unauthorized use of their password or account or any other breach of security, the User agrees to immediately notify Us. TrafficManager will not be liable for any loss or damage arising from your failure to comply with these obligations. </li>
      <li> User and Visitor acknowledge and agree that TrafficManager is not responsible for any damage, material or not experienced by legal or natural persons which could arise directly or indirectly because of a User or Visitor actions while using our Website or Services. Every Visitor and User is himself responsible and must comply with all applicable laws related to his/her use of our Website and Services. In particular, but without limitation, Users and Visitors must: (i) NOT infringe any third-party rights; (ii) NOT use the identities of other companies/individuals; (iii) NOT violate any applicable laws or regulations; (iv) NOT infringe personal non-property rights or property rights of TrafficManager and/or any 3rd parties (including intellectual property rights); (v) NOT upload any data that is contrary to any applicable law or contains any viruses or other computer programs or files that may cause damage to TrafficManager or User property, impede the User's computer use, the Website or TrafficManager Services, or interfere with the normal operation of the TrafficManager Website and Services in any way; </li>
      <li> The User acknowledge and agrees that only himself (including his/her employees, collaborators or any authorized person) is responsible for their actions while using our Website and/or Services and his/her compliance with all the applicable laws and NOT use the Website or Services for any unlawful purpose, in a way prohibited by the Terms and Conditions. </li>
      <li> When using the Website or Services the User undertakes to not perform any unlawful transactions, actions, or frauds and to not copy or make any illegal use of the information placed by Trafficmanager on the Website / Platform or any other Service Provided (The "Services"). </li>
      <li> When using the Website or Services the User undertakes to not perform any illegal activity including, but not limiting to, (i) not to use the Site and/or the Software for any illegal, offensive, obscene, or immoral purpose; (ii) not to use any automated means (spider, scraper, robot, crawler, iframe , etc.) or interface not provided by us to access the Website and/or the Services, including, without limitation, for the purpose of copying, extracting, aggregating, displaying, publishing or distributing any content or data made available via our Website or Services; (iii) cheating software, hacking tool or any other tools to disrupt, obstruct or interfere with the operation of the Website and/or the Services; (iv) not to obtain or attempt to obtain any information from the Website and/or the Services, including contact details such as but not limited to phone numbers, email addresses, social media profiles or other Instant Messenger details of other account holders or TrafficManager Services; </li>
    </ol>
    <p class="font-weight-bold">
      7. Representations and Warranties of the Users and Visitors
    </p>
    <ol>
      <li> The User or Visitor represents and warrants that when using our Website or Services he/she is 18 years or older, is fully and exclusively responsible for any use of our Website and Services, and complies with all the obligations described in these Terms and Conditions, our Client Agreement, and any other rules and conditions provided on our Website and all applicable laws. </li>
      <li> The User/Visitor has the right to make payments and represent the legal entity on behalf of which he/she visited or use our Website and Services. </li>
      <li> As specified in the Client Agreement, the User/Visitor shall pay any and all the fees and taxes related to the use of Trafficmanager Website or Services and understand and agrees that the non-performance of these obligations may result in a Service interruption and obligation to provide the full compensation for any losses incurred by Trafficmanager. </li>
      <li> The User acknowledges and agrees that she/he (including his/her employees, collaborators or any other authorized person using the Website and/or Services in its behalf) are not entitled to collect or store, or attempt to collect or store, any personal data about third parties without their knowledge and consent and are prohibited from using our Website and Services in violation of any applicable laws and regulations including but not limited to (i) upload in our Website/Platform or Services any information, data or material that is trade libelous, defamatory, obscene or harmful to minors, vulgar, racist, unlawfully threatening or harassing, abusive, promoting hatred, discriminating or displaying prejudice based on ethnic heritage, religion, race, age or sexual orientation or (ii) protected by trademark, copyright, patent, trade secret or any other intellectual property right without authorization or (iii) that violates any law or regulation including without limitation, unfair competition, anti-discrimination, the laws and regulations governing export control, false advertising or (iv) that contains any worms, viruses or any other software intended to damage or alter a computer system or data; (v) upload and process using our Website and Services any material that contains mass mailings or any form of "SPAM". </li>
    </ol>
    <p class="font-weight-bold">
      8. TrafficManager Rights and Responsibilities
    </p>
    <p>In case of a breach of these Terms and Conditions, Client Agreement, Privacy Policy or any other rules and conditions provided on our Website and all applicable laws, Trafficmanager at his sole discretion has the right to suspend, limit, partially or fully block or terminate any User or Visitor account or right to use the Website or Services including preventing the User to register again on the Website or a Visitor to re-access the Website without sending a formal notice to the User.</p>
    <p>The Visitor/User agrees that TrafficManager, in order to protect Users and Visitors from frauds or any other illegal activities, has the right to observe and collect data about the conduct of Users and Visitors.</p>
    <p>TrafficManager has the right at any time to change in part or entirely, stop or terminate the Website or update, rearrange any posted information as long as such modifications don't affect User's data.</p>
    <p>TrafficManager is entitled at any time to unilaterally suspend or terminate the transfer to any 3rd party the Website and Services cases in which TrafficManager will make reasonable commercial efforts to provide prior notifications.</p>
    <p>As described also in the TrafficManager Privacy Policy, you, as a User or Visitor authorize TrafficManager to process and store data that allow us to identify you, so you acknowledge and Agree that TrafficManager is using its own means to check if you are using our Services or Website in accordance with the Terms and Conditions, Client Agreement, Privacy Policy or any other rules and conditions provided on our Website and all applicable laws.</p>
    <p>After the the registration on the Website of the Client/User, TrafficManager or any of its business partners shall be entitled to process, transfer and store any essential data in order to identify you for billing purpose and the performance of the Terms and Conditions, Client Agreement, Privacy Policy or any other rules and conditions provided on our Website so you, as a Client/User issue your agreement to TrafficManager to transfer your data during the communication between our Website or Services and the computer systems of our business partners (es. Our Billing Providers). The purpose of such data transfer is to grant access to our services, ensure the functionality of the Website and/or Services, and to protect TrafficManager rights.</p>
    <p class="font-weight-bold">
      9. Use of the personal data
    </p>
    <ol type="a">
      <li>All the personal or company data is collected and processed in accordance with the law and our Privacy Policy located at: https://www.trafficmanager.com/privacy </li>
      <li> The User/Client/Customer of TrafficManager (as a controller) acknowledges and agrees that it shall inform its end-users about the processing of their IP addresses or about the use of cookies in their respective privacy policies and provide an opt-out. This requirement applies from the beginning of the free trial period of the TrafficManager Services and the subsequent use of our Services if the client continues to use the TrafficManager Services. The User/Client/Customer also acknowledges and agree that it shall fully comply with Art. 13 of the GDPR and provide the data subjects whose personal data is collected and processed through the TrafficManager Services on behalf of the user/client/customer with all the required information in its privacy policy. </li>
      <li> TrafficManager at its own discretion may decide to retain and store The User Account relevant information like User personal and or corporate data in case of the User Account cancellation, especially in case of an investigation or as otherwise required by law or in order to take legal action in consequence of a violation of the Terms and Conditions, Client Agreement, Privacy Policy or any other Website Rules. </li>
    </ol>
    <p class="font-weight-bold">
      10. Other Legal Provisions
    </p>
    <ol type="a">
      <li> Website Content All the content included on our Website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of TrafficManager or its content suppliers and protected by international copyright laws and any unauthorized use may violate trademark, copyright, and other laws. The compilation of all the content on this site is the exclusive property of TrafficManager or its licensors with copyright authorship for this collection by TrafficManager, and protected by international copyright laws. Any use, copying, redistribution, or publication by the Visitor or Client without prior authorization from Trafficmanager of any part of the Website or the Platform (the "Services) are strictly prohibited. The Website may contain links to 3rd party sites. TrafficManager is not responsible for the content of any linked third-party sites and doesn't make any representation regarding the accuracy of the content or any other materials on any 3rd party sites. If you decide to access any linked third-party website, you do so at your own risk. </li>
      <li> Limited Warranty <br> TRAFFICMANAGER DOES NOT WARRANT THAT THIS WEBSITE, ITS SERVERS, E-MAIL SENT, PLATFORM OR INFORMATION (The "Services") WILL OPERATE UNINTERRUPTED, ERROR-FREE, OR THAT THE SERVERS ARE FREE OF VIRUSES, SPYWARE, MALWARE OR OTHER HARMFUL COMPONENTS. FURTHER, TRAFFICMANAGER (INCLUDING OUR PROVIDERS AND OTHER OWNER'S LICENSEES) MAKES NO REPRESENTATION OR WARRANTY WITH RESPECT TO ANY RESULTS OBTAINABLE THROUGH THE SOFTWARE/PLATFORM, THE WEBSITE AND/OR ASSOCIATED PRODUCTS. NO ADVICE OR INFORMATION, WHETHER VERBAL OR WRITTEN, GIVEN BY TRAFFICMANAGER (INCLUDING PROVIDER AND OTHER OWNER'S LICENSEES) THROUGH THE PLATFORM, THE WEBSITE AND/OR OTHERWISE SHALL CREATE ANY WARRANTY, REPRESENTATION AND/OR GUARANTEE NOT EXPRESSLY STATED IN HEREIN. <br> The content of our Website, Platform or any other information made available thru the TrafficManager Services may typographically errors or inaccuracies. You agree that TrafficManager (Including his 3rd party Owner's licensees) makes no representations about the reliability, accuracy, completeness, or timeliness of the Content or about the results to be obtained from using Website or Services. The use of the TrafficManager Website, Content or Services is at your own risk. As agreed before in these Terms and Conditions, you acknowledge and Accept that changes are periodically made to the Website and may be made at any time by TrafficManager. </li>
      <li> Limitation of Liability TRAFFICMANAGER WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ITS WEBSITE OR SERVICES, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION RESULTING FROM THE USE OR INABILITY TO USE THE WEBSITE AND SERVICES SUCH AS BUT NOT LIMITED THE PLATFORM AND THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </li>
      <li> Limitation on amount of liability <br> THE AGGREGATE LIABILITY OF OWNER (INCLUDING OTHER OWNER'S LICENSEES) TO YOU FOR ALL CLAIMS ARISING FROM THE WEBSITE AND SERVICES SUCH AS BUT NOT LIMITED TO THE PLATFORM AND THE CONTENT IS LIMITED TO 3 (THREE) MONTHLY FEES FOR THE CHOSEN SUBSCRIPTION PLAN PRECEDING THE DATE OF CLAIM. <br> CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS. </li>
      <li> Indemnity <br> The User/Client/Customer agrees to indemnify, defend and hold harmless TrafficManager, including his 3rd party Owner's licensees, its, directors, officers, employees and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of the Website and the Services or your breach of these Terms and Conditions. TrafficManager shall provide notice to you promptly of any such claim, suit, or proceeding and shall assist you, at your expense, in defending any such claim suit or proceeding. </li>
      <li> User/Client data <br> All the information and data uploaded by the User or retrieved, processed by the TrafficManager Services on behalf of the User shall remain the property of the User. User have the right to copy, download, duplicate, retrieve, any of his data as stored on the Website or in the TrafficManager platform ("Services"). </li>
      <li> g. The right to use Users/Clients name and trademark <br> TrafficManager has the right to use the User/Client name or trademark (service mark) or the User logo by posting on the TrafficManager service and in promotional materials during the term of the Client Agreement and for a period of two (2) years after the termination of the Client. </li>
      <li> The use of Visitor/User/Client comments, reviews, suggestions <br> Visitor and/or User acknowledge and agree that any reviews, suggestions, comments, ideas, questions or feedback related to the Website and our Services provided in the form of email or other submissions to the Website, may shared, used or reused by TrafficManager to better serve you and that all the proposals, feedbacks and ideas made via our email or other submissions on the Website are not an intellectual property of the User and/or Visitor. <br> TrafficManager shall be entitled to the unrestricted use and dissemination of any idea, suggestion or feedback for any purpose, excepting those uses that violate the Privacy Policy. </li>
      <li> Applicable Law and Disputes Resolution <br> These Terms and Conditions are governed by the laws of Romania, EU, without regard to principles of conflict of laws, will govern this Agreement terms and any dispute of any sort that might arise between you and TrafficManager including his 3rd party Owner's licensees, its, directors, officers, employees, agents, or its associates. <br> If any provision of these Terms and Conditions is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect. No waiver of any provision of these Terms and Conditions shall be deemed a further or continuing waiver of such term or any other term. In the event of a legal dispute, it will be resolved according to dispute resolution procedures as provided in the Client Agreement. <br> In case of any conflict between these Terms and Conditions, Client Agreement and the Data Processing Addendum, the terms of the Data Processing Addendum shall control. <br> These Terms and Conditions, together with the Client Agreement, Privacy Policy and other Website Rules constitute the entire agreement between you TrafficManager with respect to the use of the Website, the Services and the Content. In case of any conflict between these Terms and Conditions (not including Data Processing Addendum), Client Agreement (not including Data Processing Addendum) and Privacy Policy, the Client Agreement shall prevail. </li>
    </ol>
    <p class="font-italic mt-5">
      Last edit: January 4, 2021
    </p>
  </div>
</template>

<script>
// import {
//   // eslint-disable-next-line no-unused-vars
//   BModal,
// } from 'bootstrap-vue'

export default {
  components: {},
  data() {
    return { }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" >
</style>
