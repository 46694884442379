<template>
  <div
    id="privacy-wrapper"
    style="text-align:justify;text-indent:30px"
  >
    <p> Your privacy is important to us. This Consent to Personal Data Processing (hereinafter "the Consent”) is meant to help you understand what data we collect, why we collect it, and how we process it when you visit our website https://trafficmanager.com (the “Website”) or use our services (the «Services», the “Software” or the “Tracking Platform”). </p>
    <p> Please take your time to read this document (policy). Your Consent to Personal Data processing is necessary to work with the TrafficManager Tracking Platform. </p>
    <p> * This form of Consent fully complies with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation) (hereinafter the “GDPR Regulation”). </p>
    <p> Traffic Manager Limited or Traffic Manager Group S.R.L (“TrafficManager”, “us”, or “we”) acts as a controller of your personal data. Traffic Manager Limited is incorporated and existent in the Republic of Malta, registration number C76756 and is located at the address 3, Advance Business Centre, Triq G. Flores, Santa Venera, SVR1950. Traffic Manager Group S.R.L is a corporation registered in the Republic of Romania, registration number 43058081 and is located at the address Mihail Kogalniceanu 17, bloc c4, ap. 14 Brasov, Romania, 500003. Contact email for both entities: privacy@trafficmanager.com. </p>
    <p> By accessing our Website or using our Services, you become subject to this Policy. Your acceptance of the terms of this Policy, in their entirety, shall be effective to you and your employees, if any. </p>
    <p> TrafficManager offers a SaaS affiliate tracking platform also known as performance marketing software through his website https://www.trafficmanager.com. Our tracking platform can be used to track, manage and optimize online marketing campaigns or to build affiliate networks for digital or physical products. Our software allows its clients/consumers/advertisers to upload, download, track, analyze and process data to and from the platform in order to review and optimize results of their marketing campaigns. </p>
    <p> The data we process on the clients/consumers/advertiser’s behalf relates to their partners with which the clients/consumers/advertisers do business and the end-users who use or interact with the clients/consumers/advertiser’s websites, products, services, advertisements campaigns and/or mobile application services. In relation to processing of such personal data, TrafficManager is a data processor. This processing of this data in the free trial period of our tracking platform and the subsequent use of the tracking platform, if the clients/consumers/advertisers choose to continue access and use of the tracking platform, is governed by the data processing agreement executed between TrafficManager and the client/customer/advertiser. </p>
    <p class="ml-4">
      <strong>1. What information we collect and how we use it</strong>
      <br> “Personal Data” is any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly. We collect the following Personal Data about you:
    </p>
    <p class="ml-4">
      <strong>1.1</strong> Information we use to provide services
    </p>
    <p>
      <strong>Your Contact and Billing Information</strong>. When you register or sign up for the TrafficManager Services, we may collect certain contact information from you, such as your first and last name, organization/company name, company number, banking or other payment information, city, state and zip code, country, email address, and phone number or other similar contact details to process your registration request, to contact you regarding it and for finance purposes (invoicing).
    </p>
    <p> We will us this information to provide you access to the Services you have ordered but also for customer support services, technical updates or updates on changes in our usage policies. </p>
    <p>TYPES, PURPOSES, METHODS AND PERIODS OF PERSONAL DATA PROCESSING</p>
    <p>Personal data for which this Consent is granted (hereinafter the “Personal Data”):</p>
    <ol type="a">
      <li>First name and last name (Processing purposes: Client identification);</li>
      <li>Gender (Processing purposes: Marketing goals, provision of effective customer service); </li>
      <li>Date of birth (Processing purposes: Client age identification);</li>
      <li>Address (Processing purposes: Client Invoicing);</li>
      <li>Contact phone number and other means of communication (including messenger apps' user IDs). (Processing purposes: Client identification, provision of support services to the client, use for marketing purposes); </li>
      <li>Email (Processing purposes: Identification of the Client account, newsletters, use for marketing purposes); </li>
      <li>Banking, card details and other payment information (Processing purposes: Billing and invoicing) Note: TrafficManager (the merchant) will not sell, purchase, provide, exchange or in any other manner disclose Account or Transaction data, or personal information of or about a Cardholder to anyone, except its Acquirer, Visa/Mastercard Corporations or in response to valid government demands </li>
      <li>Information on the Client identified locale, i.e. a set of parameters that determine regional settings of user interface, namely, residence country, time zone and the interface language of the Client. (Processing purposes: Identification of the Client account, Security, newsletters, use for marketing purposes); </li>
      <li>Technical data that is automatically transmitted by the device through which the subject uses the Controller web-site, including the device’s technical characteristics, IP address, information in the cookies files that have been sent to the subject device, information about the subject browser, the operating system name and version, the date and time of access to the site, the requested pages’ addresses. (Processing purposes: Correct operation of the web application, mobile and desktop versions of the Controller application; monitoring of the Client behavior, Security, prevention of creating duplicate accounts for fraud purpose or other illegal actions) </li>
    </ol>
    <p> Processing methods: <br>Collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, examination, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, sampling, erasure or destruction. </p>
    <p>Processing period:</p>
    <ol type="a">
      <li>If the Client/Consumer/Advertiser is a citizen or a company registered in a European Union country or has been verified to work with citizens or companies of the European Union countries, the Personal Data processing period ends in 5 (five) years from the termination of interaction between the Client/Consumer/Advertiser and Us. The Personal Data storage period may be extended from 5 (five) to 7 (seven) years upon the competent authority request. </li>
      <li>If the Client/Consumer/Advertiser is not a citizen or a company registered in a European Union country and has not been verified to work with citizens or companies of the European Union countries, the Personal Data processing period is unlimited and Personal Data may be processed by the Controller and its Processors after the termination of interaction between the Client/Consumer/Advertiser and Us. </li>
    </ol>
    <p>IN ADDITION TO THE ABOVE, I CONFIRM THAT FOR THE TIME OF THIS CONSENT I AM ACQUAINTED WITH THE FOLLOWING INFORMATION:</p>
    <p>Legal basis for the Personal Data processing: General Data Protection Regulation of April 27, 2016 (GDPR);</p>
    <p>Full name and contact details of the person authorized by the Controller and responsible for the Personal Data processing: Data Protection Officer, Mr. Francesco Montanari, email: <a href="mailto:francesco@trafficmanager.com">francesco@trafficmanager.com</a>
    </p>
    <p>
      <strong>Service Usage Information</strong>. In order to improve and provide you a better experience using our tracking platform, we may collect information in connection with your use of our Services, including order information, chat logs, email logs, support ticket logs and information about your actions in various client areas on the Website. We may use any information collected from you to improve our user experience and services, especially the customer service and support services, diagnose possible problems or to send you promotions, surveys, periodic emails regarding your order/ registration or system configuration, use of Services or other similar services you might be interested in.
    </p>
    <p>The legal basis for TrafficManager to use your Contact and Billing Information and the Service Usage Information is the agreements you enter into with us in connection with the Services (the “Services Terms” “TOS” “Terms of Sevice”) and performance of these agreements as well as taking steps at your request prior to entering into them.</p>
    <p>
      <strong>Third Party</strong>. In case of missing information about you or your company we may obtain this information from third party or public sources and add them in your account profile in the contact information section. We may need and use this combined information to provide you our Services and to be sure that we will be paid for the Services provided.
    </p>
    <p class="ml-4">
      1.2 Use of information for marketing purpose <br> Your Personal Data such as full name, contact email address, country, phone, etc. may be used by Us to send you updates about our Services or marketing offers, even if you are not an active client/customer but you indicated an interest to the TrafficManager services thru our website contact/registration/request form. If you don’t want to receive such communications from us please contact us (we will respond your request in a reasonable time period) or press the unsubscribe button you will find at the end of our messages (in case of email messages). Please remember that even if you unsubscribe and you are an active customer/client of our service we will still send you non-marketing communications regarding your account or our ongoing business relations.
    </p>
    <p class="ml-4">
      1.3 Cookies and Browsing Information
    </p>
    <p>To facilitate users' navigation on our website, TrafficManager uses cookies and other storage methods with similar functionality (hereinafter "Cookies").</p>
    <p>Cookies are small pieces of information that are saved onto the device from which the Service is accessed and which allow TrafficManager to provide certain services such as the ability to recall certain aspects of your most recent content search so that subsequent searches will be faster. Cookies can be removed from your device if you so desire. Most browsers will automatically accept Cookies. However, you can change the settings of your device to prevent Cookies being saved or to remove Cookies which may already be present on your device. Most of the services of our Website can be used without Cookies.</p>
    <p>TrafficManager uses its own Cookies and Third Party Cookies as follows:</p>
    <ol type="a">
      <li>Cookies which are strictly necessary to provide a service expressly requested by the user: Specifically, TrafficManager uses the necessary cookies to save a User's login. </li>
      <li>Session Cookies: These Cookies are saved and valid for a fixed period of time only, that is, until the user quits navigating or using the Service. These Cookies do not permanently save any information onto your device. </li>
      <li>Navigation Cookies: The primary objective of these Cookies is to avoid offering you recommendations which are unrelated to your areas of interest, as well as to provide you with targeted and personalized commercial offers. Navigation Cookies function by temporarily tracking your Internet navigation. You can remove this type of cookie before starting a navigation session in the Service </li>
    </ol>
    <p>Statistical Cookies or Analysis Cookies: These Cookies provide the following information:</p>
    <ol type="a">
      <li>The dates and times of your visits to the Service</li>
      <li>Your access to content during your visits to the Service <br>These Cookies are designed to personalize the Service, improve the user experience, create separate profiles of user activity on the websites, and generate statistics on user activity within the Platform. This allows us to focus our efforts on improving the area’s most frequently visited by users as well as help users find what they need more quickly. <br>TrafficManager may use the information regarding your visit to perform assessments and generate statistics using anonymized data, as well as to ensure uninterrupted service or to make improvements to the Service. This information will not be used for any other purposes whatsoever. All of these purposes are deemed to be non-intrusive of Users' personal and private lives. </li>
      <li> Cookies from social networks, search engines and other 3rd party tracking technologies: Specifically, Google Analytics, Google AdWords, Baidu, Bing, Yahoo, Twitter and Facebook social plugins or Linkedin button which control interaction with the widgets from these external social networks. <br> Through the use of Google Analytics, Yahoo, Bing and other 3rd Party tracking technologies we monitor, analyze and improve our Website Search Engine presence (SERP positions). We also use Bing, Yahoo, Baidu, Google Adwords and other similar AD networks for advertising purpose to drive traffic to our website, raise brand awareness, and increase our online sales as a part of our marketing plan. <br> Through the use of Social Plugins, the Platform interacts with the Twitter, Facebook and Pinterest networks to allow you to share content you find interesting with your social circles. Furthermore, the use of Social Plugins saves you from having to provide the Platform with your personal login information to fetch information already shared within the scope of said social networks. Third party cookies for behavioral marketing/retargeting: these cookies are used by trusted third companies to target registered or unregistered Users who previously have interacted with or visited the Service through any electronic means. </li>
    </ol>
    <p>Third party cookies do not obtain any information regarding users’ names or the address from where they connect. The information recorded includes for example the users’ IP from which they have access, the number of page visits, the language, the city where the user’s IP address is assigned, the number of users that visit us, and the frequency or repetition of their visits, which page you are visiting on our website. This enables TrafficManager to advertise its latest offers through the different Affiliate websites, to monitor the advertising campaign, and to show advertisements that are adapted to users’ preferences.</p>
    <p class="font-weight-bold">
      Google Search Engine: Google GDPR Compliance <br>Yahoo Search Engine: Oath GDPR Compliance <br>BING Search Engine - Microsoft GDPR Compliance <br>Baidu Search Engine: https://usa.baidu.com/privacy/ <br>Facebook GDPR Compliance <br>Twitter GDPR Compliance <br>LinkedIn GDPR Compliance
    </p>
    <p>You can configure your browser to accept or reject all Cookies by default, or to receive an on-screen notification each time a Cookie request is made at which point you can decide whether or not to allow said cookie to be saved onto your hard drive. To this end, we recommend that you consult the help section of your browser for information on how to change the settings that you currently use or use browsers in “incognito” mode. Should you decide to reject all Cookies, you will continue to be able to navigate the Platform but your access to certain sections may be limited. For more information regarding how to manage cookies, we recommend you visit <a
      href="http://www.aboutcookies.org"
      target="_blank"
    >http://www.aboutcookies.org</a>
    </p>
    <p class="ml-4">
      <strong>2.</strong> Third parties, to whom the Controller and/or the Processor may transfer the Client Personal Data for processing for the purposes and in the ways indicated in this Consent:
    </p>
    <p class="ml-4">
      <strong>2.1.</strong> Centers for personal data processing and storing (data processing centers, data centers):
    </p>
    <ol type="a">
      <li>Provided Personal Data: All Personal Data listed in this Consent;</li>
      <li>Personal Data provision purpose: Client/Customer Personal Data safe storage; </li>
    </ol>
    <p>List of data processing centers:</p>
    <p>OVH - 2 rue kellermann BP 80157 59053 ROUBAIX CEDEX 1 - France</p>
    <p class="ml-4">
      <strong>2.2.</strong> State authorities authorized to receive personal data; auditors, consultants, accountants, notaries, lawyers (if necessary):
    </p>
    <ol type="a">
      <li>Provided Personal Data: All Personal Data listed in this Consent;</li>
      <li>Personal Data provision purpose: The Controller provides the Client Personal Data solely in case of the disclosure requirement to authorized official authorities or persons in accordance with the applicable law, order, decree, court decision and in the minimum necessary extent. </li>
    </ol>
    <p class="ml-4">
      <strong>2.3.</strong> Our employees who are in contact with the Client or who are responsible for marketing, customer support, IT department who are responsible for providing support to our Client, analyzing and improving our Website and Services.
    </p>
    <p class="ml-4">
      <strong>2.4.</strong> Affiliated companies, 3rd party suppliers/vendors such as data storage services, website hosting services, database maintenance services, payment processing providers, legal consultants or debt collection companies.
    </p>
    <p>
      <strong>Note:</strong> We reserve the right to sell, assign or transfer our business or assets. In any such event or similar event, including but not limiting to corporate sale, merger, reorganization, dissolution, etc., Personal Data may be part of the transferred assets. You acknowledge that such transfers may occur, and that any acquirer or successor of TrafficManager may continue to use your Personal Data as set forth in this Policy.
    </p>
    <p>
      <strong>Personal data security ensuring means:</strong> specified in the “Policy of processing and safety of personal data” approved by the Controller. The Policy will be available on https://www.trafficmanager.com/privacy starting from May 25th, 2018.
    </p>
    <p>Validity period of the consent:</p>
    <ol type="a">
      <li>If the Client/Customer is a citizen or a company registered in a European Union country or has been verified to work with citizens or companies registered in the European Union countries, this Consent is valid for the minimum required period of the Client/Customer Personal Data processing, namely within 5 (five) years from the date of the termination of interaction between the Client/Customer and TrafficManager. The Personal Data storage period may be extended from 5 (five) to 7 (seven) years from the date of the termination of interaction between the Client/Customer and Trafficmanager; </li>
      <li>If the Client/Customer is not a citizen or a company registered in a European Union country and has not been verified to work with citizens or companies registered in the European Union countries, the Personal Data processing period for such Client/Customer is unlimited and Client/Customer consents that such Personal Data may be processed by the Controller and its Processors after the termination of the Client/Customer Agreement between Client/Customer and TraficManager. </li>
    </ol>
    <p class="font-weight-bold">
      Special rules for Client/Customers that are citizens of European Union countries or have been verified to work with citizens of the European Union countries:
    </p>
    <ol type="a">
      <li> Consent withdrawal: If the Client/Customer is a citizen or a company registered in a European Union country or has been verified to work with citizens or companies registered in the European Union countries, the Controller is obliged to comply with the GDPR Directive on the specified above minimum period for such Client/Customer Personal Data storage. The Personal Data Subject understands, agrees and confirms that the right to withdraw this Consent cannot be applied prior to the mandatory Personal Data storage period expiration in accordance with the provisions of the GDPR Directive; <br>
        <br> If the Client/Customer is a citizen or a company registered in a European Union country or has been verified to work with citizens or companies registered in the European Union countries has not withdrawn this Consent after five (5) years following to the termination of interaction between the Client/Customer and TrafficManager. in the Client/Customer Agreement framework, the Controller, provided that the competent authority does not require the extension of the Personal Data processing to 7 (seven) years period, at the end of this five-year period erases the personal data by irrevocable destruction, and also informs all third parties to whom the Controller and/or Processors transferred the Client/Customer Personal Data regarding such erasure, and demands the implementation of similar actions on their part.
      </li>
      <li> Client/Customer that is a citizen or a company registered in a European Union country or has been verified to work with citizens or companies registered in the European Union countries is also notified that at any time of the Consent validity period he has the right to: <br>
        <ol>
          <li>Access the Personal Data, i.e. has the right to request and obtain from the Controller a confirmation as to whether or not personal data are being processed / access to Personal Data and the following information: the purposes of the processing; the categories of personal data concerned, all possible recipients of the Personal Data, where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period; </li>
          <li>Obtain from the Controller without undue delay the rectification of inaccurate Personal Data, the completion of Client/Customer’s incomplete Personal Data, as well as require the receipt of notifications from the Controller regarding any corrections, additions, erasures or limitations to the Personal Data processing; </li>
          <li>Erasure of the Personal Data (“right to be forgotten”) if: personal data no longer necessary in relation to the purposes for which they were collected or otherwise processed; the Personal Data Subject withdraws the Consent, and there is no other legal ground for the processing; the Personal Data Subject objects to the Personal Data processing and there are no overriding legitimate grounds for the processing; Personal Data has been unlawfully processed; Personal Data has to be erased for compliance with a legal obligation in Union or Member State law to which the controller is subject; Personal Data has been collected in relation to the offer of information society services to a child (taking into account the provisions of Article 8 (1) of GDPR); </li>
          <li>Obtain restriction of Personal data processing if: the accuracy of the personal data is contested by the Client/Customer, for a period enabling the Controller to verify the accuracy of the personal data; the processing is unlawful and the Client/Customer opposes the erasure of the personal data and requests the restriction of their use instead; the Controller no longer needs the personal data for the purposes of the processing, but they are required by the Client/Customer for the establishment, exercise or defense of legal claims; the Client/Customer has objected to processing pending the verification whether the legitimate grounds of the Controller override those of the Client/Customer; </li>
          <li>Personal Data portability, i.e. to receive the Personal Data, which Client/Customer has provided to the Controller, in a structured, commonly used and machine-readable format and have the right to transmit those data to another controller; </li>
          <li>Object to Personal Data processing, and as result the Controller shall no longer process the personal data unless the Controller demonstrates compelling legitimate grounds for the Personal Data processing which override the interests, rights and freedoms of the Client/Customer or for the establishment, exercise or defense of legal claims (where personal data is processed for direct marketing purposes, the Client/Customer shall have the right to object to processing of personal data for such marketing, and the Controller shall no longer process the Personal Data for such purposes); </li>
          <li>File a complaint regarding the Controller/Processors actions to the supervisory authority. </li>
        </ol>
      </li>
    </ol>
    <p>
      <strong>Refusal to grant the Consent:</strong> Personal Data Subject hereby confirms that he has been notified that the provision of this Consent is not mandatory and the Client/Customer may at any time refuse it. However, the Consent granted by the Client/Customer is a requirement necessary for the conclusion of the Client/Customer Agreement between the Client/Customer and TrafficManager. In the case of absence of the Client/Customer signed Consent, the contractual relationship between the Client/Customer and TrafficManager. does not arise.
    </p>
    <p class="ml-4">
      <strong>3.</strong> Security: We have implemented cutting-edge technical and organizational security measures to protect the data in our possession from any kind of unfortunate incidents as accidental data loss, misuse, alteration, disclosure, destruction or unauthorized access, as well, access of your data is limited only to authorized personnel that will process your data subject to a duty of confidentiality. However, no data transmission on the internet can be guaranteed 100% to be safe from intrusion but we have a set of procedures to follow in case we need to deal with any suspected or actual data security breach. In such case, we will notify you right away and the supervisory authority of a suspected breach where we are legally required to do so.
    </p>
    <p class="ml-4">
      <strong>4.</strong> Data collecting from Minors <br>
      <br> TrafficManager will never knowingly collect any Personal Data or information about minor age individuals (under eighteen (18) years of age). If we obtain actual knowledge that it has collected personal information about a minor individual, we will delete immediately all this information from our database
    </p>
    <p class="ml-4">
      <strong>5.</strong> Client/Customer Rights, Questions and Complaints
    </p>
    <ol
      class="ml-4"
      type="a"
    >
      <li>You have the right to request any modification/rectification of your personal data saved in our database such as Contact and Billing info by contacting us at support@trafficmanger.com or by opening a support ticket via your client dashboard. </li>
      <li>You have the right to restrict, refuse or revoke your consent to the processing of your Personal Data by contacting us at support@trafficmanager.com, However, it can be difficult or impossible to provide Services to you upon termination of processing of your Personal Data. </li>
      <li>You can exercise your right to data portability (copy of your Personal Data which you have provided to us in structured, common and machine-readable format if has not been already deleted) or request information about all Personal Data we have stored about you by contacting us at support@trafficmanager.com </li>
      <li>If you have any questions, concerns or complaints regarding the way we collect and handle your information, please contact us by email at support@trafficmanager.com or privacy@trafficmanager.com </li>
    </ol>
    <p class="font-italic">
      Date of last revision: 15 October 2020
    </p>
  </div>
</template>

<script>
// import {
//   // eslint-disable-next-line no-unused-vars
//   BModal,
// } from 'bootstrap-vue'

export default {
  components: {},
  data() {
    return { }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" >
</style>
