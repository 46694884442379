<template>
  <!-- eslint-disable -->
  <section
    v-if="formData.dateIssued"
    class="invoice-preview-wrapper"
  >

    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        v-show="!isLoading"
        id="printMe"
        ref="printMe"
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

              <!-- Header: Left Content -->
              <div class="d-flex">
                <div class="logo-wrapper">
                  <b-img
                    :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                    alt="logo"
                  />
                  <h3 class="text-primary invoice-logo ml-0">
                    {{ appName }}
                  </h3>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ formData.invoiceNo }}
                </h4>
                <div class="invoice-date-wrapper mb-0">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ formData.dateIssued }}
                  </p>
                </div>
                <div
                  v-if="formData.dueDate"
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Due Date:
                  </p>
                  <p class="invoice-date">
                    {{ formData.dueDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <div class="invoice-spacing d-flex justify-content-between">
              <!-- Col: Invoice To -->
              <div
                class="p-0"
              >
                <h6 class="mb-2">
                  From:
                </h6>
                <h6 class="mb-25 font-weight-bolder">
                  {{ formData.systemSettings.company_name }}
                </h6>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.company_address1 }}
                </p>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.company_address2 }}
                </p>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.vatno }}
                </p>
              </div>

              <!-- Col: Payment Details -->
              <div
                class="p-0"
              >
                <h6 class="mb-2">
                  To:
                </h6>
                <h6 class="mb-25 font-weight-bolder">
                  {{ formData.client }}
                </h6>
                <p class="card-text mb-25 invoice-to-address">
                  {{ formData.address + ', ' + formData.country }}
                </p>
                <p
                  v-if="formData.tax"
                  class="card-text mb-25"
                >
                  Tax-ID/VAT: {{ formData.tax }}
                </p>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="formData.invoiceData.items"
            :fields="['itemTitle', 'qty', 'unitPrice', 'itemPrice']"
          >

            <template #head(itemTitle)>
              <span>Item</span>
            </template>

            <template #head(unitPrice)>
              <span>Unit Price</span>
            </template>

            <template #head(itemPrice)>
              <span>Item Price</span>
            </template>

            <template #cell(itemTitle)="data">
              <b-card-text class="font-weight-bold">
                {{ data.value.text ? data.value.text : data.value }}
              </b-card-text>
            </template>

          </b-table-lite>

          <p
            v-if="formData.periodDate.startDate"
            class="w-100 invoice-period"
          >
            Period: {{ formData.periodDate.startDate + ' - ' + formData.periodDate.endDate }}
          </p>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Total -->
              <b-col
                cols="12"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + parseFloat(formData.subTotal).toFixed(2) }}
                    </p>
                  </div>
                  <div
                    v-if="formData.discountValue > 0"
                    class="invoice-total-item"
                  >
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + parseFloat(formData.discountValue).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      VAT ({{ formData.vatPercentage }}%):
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + parseFloat(formData.vat).toFixed(2) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + parseFloat(formData.totalToPay).toFixed(2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <span v-if="invoiceType === 'invoice'">
            <p
              v-if="invoiceDetails.version === 2 && invoiceDetails.currency !== 'RON'"
              class="text-right mt-1 mr-2 pr-1 mb-0"
            >
              Total in Lei (exchange rate BNR: {{ invoiceDetails.exchange_rate }} ): {{ parseFloat(invoiceDetails.exchange_rate * invoiceDetails.invoice_value).toFixed(2) }} RON
            </p>
          </span>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body
            class="invoice-padding text-center pt-0"
          >
            <p v-if="formData.existingClients">
              Exempt by virtue of Art 7 of the Twelfth Schedule to the Value Added Tax Act - Chapter 406
            </p>
            <p v-else>
              Customer to account for any VAT arising on this supply in accordance with Article 196 Council Directive 2016/112/EC
            </p>
            <p
              v-if="payment && formData.invoiceStatus!=='pending'"
              class="paid-style mt-1"
            >
              <span v-if="payment.paidWith === 'creditCard'">
                Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.payment + 'I' + payment.invoice }}
              </span>
              <span v-else-if="payment.paidWith === 'credit_card'">
                Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref ? payment.ipc_trnref : invoiceDetails.invoice_number_v2 }}
              </span>
              <span v-else-if="payment.paidWith === 'payPal'">
                Paid by PayPal on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref }}
              </span>
              <span v-else-if="payment.paidWith === 'wire'">
                Paid by Wire Transfer on {{ payment.payment_date }}
              </span>
            </p>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <div v-if="formData.invoiceStatus==='pending' || formData.invoiceStatus==='overdue'">
            <div>
              <div v-if="setting.auto_payment_mode && isAntiFraudInvoice===false">
                <p
                  style="text-align:justify; text-indent: 25px;"
                >
                  You enabled automatic payments <b>{{ autoPaymentText }}</b>, and the invoice will be charged automatically on the due date.
                </p>
                <p style="text-align:justify; text-indent: 25px;">
                  Thank you.
                </p>
                <p style="text-align:justify; text-indent: 25px;">
                  To disable this feature, go to your account setting and look for recurring payment option. <router-link to="/account">
                    Click here!
                  </router-link>
                </p>
              </div>
              <div v-else>
                <p v-if="formData.invoiceStatus==='overdue'">
                  Payment is <b class="note">OVERDUE</b>
                </p>
                <p v-else-if="setting.auto_payment_mode == 0 || invoice.type === type_opt_feature">
                  Payment is <b class="note">PENDING</b>
                </p>
              </div>
              <p v-if="setting.auto_payment_mode === 0 || isAntiFraudInvoice">
                <b-form-checkbox
                  v-model="selected"
                  :value="subscribe"
                  @change="toggleEnableButtons"
                >
                  I have read and accepted the
                  <a
                    href="#"
                    @click="showPrivacy"
                  >Privacy</a>,
                  <a
                    href="#"
                    @click="showTerms"
                  >Terms of Use</a>, and
                  <a
                    href="#"
                    @click="showBillingPolicy"
                  >Billing & Refund Policy</a>.
                </b-form-checkbox>
              </p>
            </div>
            <div v-if="setting.auto_payment_mode === 0 || isAntiFraudInvoice">

              <b-button
                v-if="isAntiFraudInvoice && hasSingleCard"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar-send-invoice
                variant="primary"
                class="mb-75"
                block
                :disabled="disabledButton"
                @click="useCC()"
              >
                <span v-if="payingCardSingle">
                  <b-spinner
                    small
                    type="grow"
                  />
                  {{ antiFraudCardLabel }}
                </span>
                <span v-else>{{ antiFraudCardLabel }}</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar-send-invoice
                variant="primary"
                class="mb-75"
                block
                :disabled="disabledButton"
                @click="payWithCard()"
              >
                <span v-if="payingCard">
                  <b-spinner
                    small
                    type="grow"
                  />
                  Paying with Credit Card
                </span>
                <span v-else>Pay with Credit Card</span>
              </b-button>


              <b-button v-if="invoice.currency === 'EUR'"
                  v-b-modal.preprocess-stripe-payments
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-75"
                  block
                  :disabled="disabledButton"
                >
                  Pay with AMEX & Others (Stripe)
                </b-button>

              <b-button
                v-if="invoice.currency != 'RON'"
                ref="paypalbtn"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar-send-invoice
                variant="primary"
                class="mb-75"
                block
                :disabled="disabledButton"
                @click="paypalPayment"
              >
                <span v-if="payingPaypal">
                  <b-spinner
                    small
                    type="grow"
                  />
                  Paying with Paypal
                </span>
                <span v-else>Pay with PayPal</span>
              </b-button>

              <!-- enabling payment recurring feature is located at the account setting -->
              <!-- <b-button
                  v-if="invoice.currency != 'RON' && user_status == 5 && !hasDiscount && setting.auto_payment_mode == 0 && optional_features.length == 0 && hasAtLeastOneInvoice"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-toggle.sidebar-send-invoice
                  variant="primary"
                  class="mb-75"
                  block
                  :disabled="disabledButton"
                  onclick="alert('todo...')"
                >
                  Pay with PayPal (recurring)
                </b-button> -->



              <b-button
                v-b-modal.Wire-Transfer-modal
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar-send-invoice
                variant="primary"
                class="mb-75"
                block
                :disabled="disabledButton"
              >
                Wire transfer
              </b-button>

              <b-button
                v-if="invoice.currency === 'EUR'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                :disabled="disabledButton"
                @click="cryptoALT5Payment"
              >
                Pay with Crypto
              </b-button>

              <b-modal
        id="preprocess-stripe-payments"
        title="AMEX (Stripe) Payment"
        no-close-on-backdrop
        hide-header-close
        ok-title="Continue"
        @ok="processStripePayments"
      >
        <h5 class="px-2 text-center" style="margin-top: 20px;">
          Please be aware that a processing fee of <strong>2%</strong> will be applied to payments made with <strong>AMEX (Stripe)</strong>.<br><br>
          To avoid processing fees, please pay using the <br><strong>"Pay with Credit Card"</strong> option.
        </h5>
      </b-modal>


              <b-modal
                id="send-alt5-payments"
                title="Crypto Payment"
                no-close-on-backdrop
                hide-header-close
                no-close-on-esc
                ok-only
                @ok="cryptoALT5PaymentSend"
              >
                <h5
                  class="px-2 text-center"
                  style="margin-top: 20px;"
                >
                  We are waiting for your payment to be confirmed by the network.<br>Please come back later. Thank you.
                </h5>
              </b-modal>

              <div
                alt5="Paywidget"
                alt5_id="be180908-946c-4392-89b3-56be054f741b"
                currency="EUR"
                :ref_id="invoice.credit_note"
                id="alt5_payment"
                style="display: none;"
              >
              </div>
              <script type="application/javascript" src="https://alt5pay.com/widget/alt5pay.js"></script>
            </div>

          </div>
          <div v-else-if="formData.invoiceStatus==='paid'">
            THIS INVOICE IS PAID. Thank you.
            <br><br>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
              @click="download"
            >
              <span v-if="downloading">
                <b-spinner
                  small
                  type="grow"
                />
                Downloading...
              </span>
              <span v-else>Download</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Wire Transfer Modal  -->
    <b-modal
      id="Wire-Transfer-modal"
      cancel-variant="outline-secondary"
      hide-footer
      size="lg"
      title="Wire Transfer - Payment"
    >
      <div
        v-if="!wireTransfer"
        class="text-center"
      >
        <table class="customTable">
          <tr><td class="leftcell">
            Date
          </td><td>{{ currentDate }}</td></tr>
          <tr><td>Status</td><td>Pending</td></tr>
        </table>
        <p class="note">
          Send a wire transfer to the following account:
        </p>
        <table class="customTable">
          <tr><td class="leftcell">
            IBAN
          </td><td>
            <span class="iban_code">{{ bankingProfile.iban_code }}</span>
            <b-button
              type="button"
              style="float:right"
              size="sm"
              @click="copyIban"
            >Copy</b-button>
          </td></tr>
          <tr><td class="leftcell">
            SWIFT
          </td><td>
            <span class="swift_code">{{ bankingProfile.bic_swift }}</span>
            <b-button
              type="button"
              style="float:right"
              size="sm"
              @click="copySwift"
            >Copy</b-button>
          </td></tr>
          <tr><td class="leftcell">
            Account holder name
          </td><td>
            <span class="account_holder_name">{{ bankingProfile.company_name }}</span>
            <b-button
              type="button"
              style="float:right"
              size="sm"
              @click="copyAccountHolderName"
            >Copy</b-button>
          </td></tr>
          <tr><td class="leftcell">
            Bank name
          </td><td>
            <span class="bank_name">{{ bankingProfile.bank_name }}</span>
            <b-button
              type="button"
              style="float:right"
              size="sm"
              @click="copyBankName"
            >Copy</b-button>
          </td></tr>
          <tr><td class="leftcell">
            Amount
          </td><td>
            <span class="cpy_amount">{{ invoice.currency }} {{ parseFloat(invoice.invoice_value).toFixed(2) }}</span>
            <b-button
              type="button"
              style="float:right"
              size="sm"
              @click="copyWireAmount"
            >Copy</b-button>
          </td></tr>
        </table>

        <b-button
          variant="success"
          block
          style="margin-top:8px; width: 200px;float: right;"
          :disabled="sendingWire"
          @click="payWithWireTransfer"
        >
          <span v-if="sendingWire">
            <b-spinner
              small
              type="grow"
            />
            Saving...
          </span>
          <span v-else> I've sent the funds</span>
        </b-button>
      </div>
      <div
        v-else
        class="text-center"
        style="padding:30px"
      >
        <h5>You have a pending wire transfer on this invoice.</h5>
      </div>
    </b-modal>

    <!-- Btc Transfer Modal  -->
    <b-modal
      id="btc-Transfer-modal"
      cancel-variant="outline-secondary"
      hide-footer
      size="lg"
      title="Pay with Crypto"
      ize="lg"
    >
      <div
        v-if="!btcTransfer"
        class="text-center btc-modal"
      >
        <table class="customTable">
          <tr><td class="leftcell">
            Date
          </td><td>{{ currentDate }}</td></tr>
          <tr><td>Status</td><td>Pending</td></tr>
        </table>
        <p class="note">
          Send a BTC transfer to the following wallet:
        </p>
        <table class="customTable">
          <tr><td class="leftcell">
            BTC Address
          </td><td>
            <span
              contenteditable="true"
              class="btc_address"
            >
              {{ btcProfile.btc_address }}
            </span>
            <b-button
              type="button"
              style="float:right"
              size="sm"
              @click="copyBtcAddress"
            >Copy
            </b-button>
          </td></tr>
          <tr><td class="leftcell">
            Amount
          </td><td>€
            <span
              contenteditable="true"
              class="amount"
            >{{ parseFloat(invoice.valueInEuroNoVat).toFixed(2) }}</span>
            <b-button
              type="button"
              style="float:right"
              size="sm"
              @click="copyAmount"
            >Copy</b-button>
          </td></tr>
          <tr><td class="leftcell">
            Amount in BTC
          </td><td>
            <span
              contenteditable="true"
              class="amountInBtc"
            >{{ amountInBTC(invoice.valueInEuroNoVat,btcProfile.btcRate) }}</span>
            <b-button
              type="button"
              style="float:right"
              size="sm"
              @click="copyAmountInBtc"
            >Copy</b-button></td></tr>
          <tr><td class="leftcell">
            Live Rate BTC-EUR
          </td><td>{{ btcProfile.btcRate }} <a
            class="btn btn-info sm"
            target="_blank"
            href="https://www.coinbase.com/price/bitcoin"
            style="float:right"
          >source: Coinbase.com</a></td></tr>
        </table>
        <b-button
          variant="success"
          block
          style="margin-top:8px; width: 200px;float: right;"
          :disabled="sendingBtc"
          @click="payWithCrypto"
        >
          <span v-if="sendingBtc">
            <b-spinner
              small
              type="grow"
            />
            Saving...
          </span>
          <span v-else> I've sent the funds</span>
        </b-button>
      </div>
      <div
        v-else
        class="text-center"
        style="padding:30px"
      >
        <h5>You have a pending BTC transfer on this invoice.</h5>
      </div>
    </b-modal>

    <b-modal
      id="terms-modal"
      scrollable
      title="Terms of Use"
    >
      <terms-of-use />
    </b-modal>

    <b-modal
      id="privacy-modal"
      scrollable
      title="Privacy Policy"
    >
      <privacy />
    </b-modal>

    <b-modal
      id="billing-policy-modal"
      scrollable
      title="Billing & Refund Policy"
    >
      <billing-policy />
    </b-modal>

    <b-modal
      id="preprocess-alt5-payments"
      title="Crypto Payment"
      no-close-on-backdrop
      hide-header-close
      ok-title="Continue"
      @ok="cryptoALT5Payment"
    >
      <h5
        class="px-2 text-center"
        style="margin-top: 20px;"
      >Please be aware that a processing fee of 5% will be applied to all crypto payments.</h5>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BButton, BImg, BCardText, VBToggle, BSpinner, BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import Vue from 'vue'
import VueHtml2Canvas from 'vue-html2canvas'
import axios from '@/libs/axios'
import moment from 'moment'

import Terms from './Terms.vue'
import Privacy from './Privacy.vue'
import BillingPolicy from './BillingPolicy.vue'

Vue.use(VueHtml2Canvas)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BButton,
    BImg,
    BCardText,
    BSpinner,
    BModal,
    BFormCheckbox,
    'terms-of-use': Terms,
    privacy: Privacy,
    'billing-policy': BillingPolicy,
  },
  data() {
    return {
      downloading: false,
      isAntiFraudInvoice: false,
      hasSingleCard: false,
      antiFraudCardLabel: '',
      payingPaypal: false,
      payingCardSingle: false,
      payingCard: false,
      disabledButton: true,
      hasAtLeastOneInvoice: 0,
      isOldMaltaUser: false,
      old_malta_users: [],
      selected: [],
      subscribe: 0,
      btcTransfer: null,
      sendingWire: false,
      sendingBtc: false,
      bankingProfile: {
        id: 0,
        iban_code: '',
        bic_swift: '',
        company_name: '',
        bank_name: '',
      },
      btcProfile: {
        id: 0,
        btc_address: '',
        btcRate: 0,
      },
      invoice: {
        credit_note: 0,
        invoice_value: 0,
        currency: 0,
        valueInEuroNoVat: 0,
        type: '',
        invoice: 0,
      },
      setting: {
        auto_payment_mode: 0,
        type: '',
      },

      currentDate: moment(new Date()).format('DD/MM/YYYY'),
      wireTransfer: null,
      invoiceId: 0,

      isLoading: true,
      status: 'Paid',
      invoiceType: '',
      invoiceDetails: {},
      payment: null,
      items: {},
      appName: null,
      pdfFilename: '',
      userId: 0,

      formData: {
        invoice_status: '',
        dateIssued: '',
        dueDate: '',
        invoiceTo: null,
        client: '',
        address: '',
        tax: '',
        country: '',
        subTotal: 0,
        totalToPay: 0,
        vat: 0,
        vatPercentage: 0,
        bankDetails: '',
        existingClients: false,
        systemSettings: null,
        invoiceNo: 0,
        currency: '',
        discountValue: 0,
        periodDate: {
          startDate: null,
          endDate: null,
        },
        invoiceData: {
          items: [],
        },
      },
    }
  },
  created() {
    this.isAntiFraudInvoiceFn()
    this.getInvoiceByID()
    this.appName = $themeConfig.app.appName
  },
  mounted() {
    // eslint-disable-next-line arrow-parens
    document.body.onclick = (e) => {
      if (e.target.classList.contains('btnalt5pay')) this.$bvModal.show('send-alt5-payments')
    }
    this.checkUrlForError()
  },
  methods: {
    processStripePayments() {
      window.location.href = `${process.env.VUE_APP_PORTAL}stripe/invoice/${this.invoice.credit_note}?isCustom=true`
    },
    checkUrlForError() {
      const urlParams = new URLSearchParams(window.location.search)
      const error = urlParams.get('error')
      if (error) {
        this.notify({
          text: error,
          variant: 'danger',
        })
        window.history.pushState({}, document.title, window.location.pathname)
      }
    },
    preprocessALT5Payment() {
      const button = document.getElementById('alt5_payment')
      const amount = (parseFloat(this.invoice.invoice_value) * 0.05 + parseFloat(this.invoice.invoice_value))
      button.setAttribute('amount', amount)
      this.$bvModal.show('preprocess-alt5-payments')
    },
    cryptoALT5Payment() {
      const button = document.getElementById('alt5_payment')
      button.click()
    },
    cryptoALT5PaymentSend() {
      this.$router.push('/home')
    },
    getInvoiceByID() {
      const user = this.$store.state.auth.userData
      this.$store.dispatch('invoice/getCustomInvoiceByID', this.$route.params.id)
        .then(res => {
          this.old_malta_users = res.OLD_MALTA_USERS
          this.hasAtLeastOneInvoice = res.hasAtLeastOneInvoice
          if (this.old_malta_users.indexOf(user.id) >= 0) this.isOldMaltaUser = true

          this.formData.invoiceStatus = res.invoice.invoice_status

          this.setting.auto_payment_mode = Number(res.setting.auto_payment_mode)
          if (this.setting.auto_payment_mode === 1) this.autoPaymentText = 'with your Paypal'
          else if (this.setting.auto_payment_mode === 2) this.autoPaymentText = 'with your Credit Card'

          this.payment = res.payment
          this.invoiceType = res.invoiceType
          this.invoiceDetails = res.invoice
          this.pdfFilename = this.formData.invoiceNo

          this.invoice.credit_note = res.invoice.credit_note
          this.invoice.invoice_value = res.invoice.total
          this.invoice.currency = res.invoice.currency
          this.invoice.valueInEuroNoVat = res.valueInEuroNoVat
          this.invoice.type = res.invoice.type
          this.invoice.version = res.invoice.version

          this.bankingProfile.id = res.bankingProfile.id
          this.bankingProfile.iban_code = res.iban

          this.bankingProfile.bic_swift = res.bankingProfile.bic_swift
          this.bankingProfile.company_name = res.bankingProfile.company_name
          this.bankingProfile.bank_name = res.bankingProfile.bank_name

          this.btcProfile.id = res.btcProfile.id
          this.btcProfile.btc_address = res.btcProfile.btc_address
          this.btcProfile.btcRate = res.btcRate
          if (typeof this.btcProfile.btcRate === 'undefined') this.btcProfile.btcRate = 0

          this.formData.invoice_status = res.invoice.invoice_status
          this.formData.systemSettings = res.systemSettings
          this.formData.invoiceNo = res.invoice.version === 1 ? `Invoice# ${res.invoice.invoiceNo}` : `Invoice#  TMC${res.invoice.invoice_number_v2.toString().padStart(4, '0')}`

          this.formData.dateIssued = moment(new Date(res.invoice.issueDate)).format('DD MMM YYYY')
          this.formData.dueDate = moment(new Date(res.invoice.dueDate)).format('DD MMM YYYY')
          this.formData.client = res.invoice.to
          this.formData.address = res.invoice.userAddress
          this.formData.country = res.billingCountry
          this.formData.tax = res.invoice.userVAT
          this.formData.subTotal = parseFloat(res.invoice.subtotal).toFixed(2)
          this.formData.totalToPay = parseFloat(res.invoice.total).toFixed(2)
          this.formData.vat = parseFloat(res.invoice.vatValue).toFixed(2)
          this.formData.vatPercentage = res.invoice.vatPercent
          this.formData.periodDate.startDate = res.invoice.periodStart
          this.formData.periodDate.endDate = res.invoice.periodEnd
          this.formData.currency = res.invoice.currency
          this.userId = res.invoice.uid

          const cur = res.invoice.currency.toUpperCase().trim()

          let itemPriceFirst = res.invoice.packageItemPrice
          let unitPriceFirst = res.invoice.packageUnitPrice
          if (cur === 'RON') {
            itemPriceFirst = res.invoice.packageItemPrice * res.invoice.exchange_rate
            unitPriceFirst = res.invoice.packageUnitPrice * res.invoice.exchange_rate
          }

          if (res.invoice.package != null) {
            this.formData.invoiceData.items.push({
              itemTitle: res.invoice.packageName,
              qty: res.invoice.packageQty,
              unitPrice: `${res.invoice.currency} ${parseFloat(unitPriceFirst).toFixed(2)}`,
              itemPrice: `${res.invoice.currency} ${parseFloat(itemPriceFirst).toFixed(2)}`,
            })
          }

          res.items.forEach(item => {
            let itemPrice = item.customItemPrice
            let unitPrice = item.customUnitPrice

            if (cur === 'RON') {
              itemPrice = item.customItemPrice * res.invoice.exchange_rate
              unitPrice = item.customUnitPrice * res.invoice.exchange_rate
            }

            this.formData.invoiceData.items.push({
              itemTitle: item.customProductName, qty: item.customQty, unitPrice: `${res.invoice.currency} ${parseFloat(unitPrice).toFixed(2)}`, itemPrice: `${res.invoice.currency} ${parseFloat(itemPrice).toFixed(2)}`,
            })
          })
          this.isLoading = false
        })
        .catch(e => {
          if (e.response.status === 404) this.$router.push('/error-404')
          else this.$router.push('/not-authorized')
        })
    },

    async useCC() {
      this.payingCardSingle = true
      this.disabledButton = true
      const payload = { credit_note: this.invoice.credit_note }
      await axios.post('/api/antifraud/use_cc', payload).then(res => {
        const paymentStatus = Number(res.data.payment_status)
        if (paymentStatus === 1) {
          this.notify({
            text: 'Payment is successful. Thank you!',
            variant: 'success',
          })
          this.$router.push('/anti-fraud-credits')
        } else {
          this.notify({
            text: 'Payment is not successful. Please try again or please try other payment method.',
            variant: 'danger',
          })
        }
        this.payingCardSingle = false
        this.disabledButton = false
      })
      this.payingCardSingle = false
      this.disabledButton = false
    },

    async payWithWireTransfer() {
      this.sendingWire = true
      const payload = {
        creditNote: this.invoice.credit_note,
        bankingProfile: this.bankingProfile.id,
      }
      const response = await axios.post('/api/wire_transfer/create_payments', payload)
      if (response.status === 200) {
        this.notify({
          text: 'Payment has been successfully submitted. We will verify the wire transfer at the soonest possible time. Thank you.',
          variant: 'success',
        })
        if (this.$store.state.auth.impersonate === true) {
          this.$swal({
            text: 'Do you want to redirect to the list of wire transfers?',
            icon: 'question',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary mr-1',
              cancelButton: 'btn btn-secondary',
            },
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            buttonsStyling: false,
          }).then(res => {
            if (res.isConfirmed) {
              this.$store.dispatch('auth/exitImpersonation').then(() => {
                window.location = '/billing/wire-transfers'
              })
            } else {
              this.$router.push('/home')
            }
          })
        } else this.$router.push('/home')
      } else {
        this.notify({
          text: 'Payment has not been submitted. We will verify the error at the earliest possible time. Thank you.',
          variant: 'danger',
        })
      }
      this.sendingWire = false
    },

    async payWithCrypto() {
      this.sendingBtc = true
      const payload = {
        creditNote: this.invoice.credit_note,
        btcProfile: this.btcProfile.id,
      }
      const response = await axios.post('/api/btc_transfer/create_payments', payload)
      if (response.status === 200) {
        this.notify({
          text: 'Payment has been successfully submitted. We will verify the wire transfer at the soonest possible time. Thank you.',
          variant: 'success',
        })
        this.$router.push('/billing/btc-transfer')
      } else {
        this.notify({
          text: 'Payment has not been submitted. We will verify the error at the earliest possible time. Thank you.',
          variant: 'danger',
        })
      }
      this.sendingBtc = false
    },

    copyBtcAddress() {
      const range = this.getRange('btc_address')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyAmount() {
      const range = this.getRange('amount')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyAmountInBtc() {
      const range = this.getRange('amountInBtc')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyIban() {
      const range = this.getRange('iban_code')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copySwift() {
      const range = this.getRange('swift_code')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyAccountHolderName() {
      const range = this.getRange('account_holder_name')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyBankName() {
      const range = this.getRange('bank_name')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copyWireAmount() {
      const range = this.getRange('cpy_amount')
      this.copySelectedText(range)
      this.notifyCopiedText(range)
    },

    copySelectedText(range) {
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
      document.execCommand('copy')
    },
    getRange(classname) {
      const el = document.getElementsByClassName(classname)[0]
      const range = document.createRange()
      range.selectNodeContents(el)
      return range
    },
    notifyCopiedText(strcpd) {
      const str = strcpd.toString().trim()
      this.notify({
        text: `"${str}" is copied!`,
        variant: 'success',
      })
    },

    toggleEnableButtons() {
      if (this.selected.length) this.disabledButton = false
      else this.disabledButton = true
    },
    showTerms() {
      this.$bvModal.show('terms-modal')
    },
    showPrivacy() {
      this.$bvModal.show('privacy-modal')
    },
    showBillingPolicy() {
      this.$bvModal.show('billing-policy-modal')
    },
    async paypalPayment() {
      this.payingPaypal = true
      this.$refs.paypalbtn.disabledButton = true
      const payload = {
        invoice: this.invoice.credit_note,
        type: this.invoiceType,
        invoiceNo: this.formData.invoiceNo,
      }
      // console.log(payload)
      const res = await this.$store.dispatch('invoice/getPaypalUrl', payload)
      if (typeof res.url !== 'undefined') window.location = res.url
    },

    payWithCardSingle() {
      this.payingCardSingle = true
      const payload = {
        invoice: this.invoice.credit_note,
        is_custom: true,
      }
      this.cardPayment(payload)
    },
    payWithCard() {
      this.payingCard = true
      const payload = {
        invoice: this.invoice.credit_note,
        isRecurrent: true,
        is_custom: true,
      }
      this.cardPayment(payload)
    },
    async cardPayment(payload) {
      const uri = '/api/euplatesc/checkout'
      const response = await axios.post(uri, payload)
      if (response.status === 200) {
        const { data } = response
        let orderDesc = ''
        if (data.order_desc) orderDesc = data.order_desc

        // formData object is just use to map the data properly,
        // or you can use regular array.
        const formData = new FormData()
        formData.append('amount', data.amount)
        formData.append('curr', data.curr)
        formData.append('invoice_id', data.invoice_id)
        formData.append('order_desc', orderDesc)
        formData.append('merch_id', data.merch_id)
        formData.append('timestamp', data.timestamp)
        formData.append('nonce', data.nonce)
        formData.append('recurent_freq', data.recurent_freq)
        formData.append('recurent_exp', data.recurent_exp)
        formData.append('recurent', data.recurent)
        formData.append('email', data.email)
        formData.append('lang', data.lang)
        formData.append('fp_hash', data.fp_hash)
        formData.append('ExtraData[silenturl]', data.silenturl)
        formData.append('ExtraData[successurl]', data.successurl)

        // create a actual form
        const ccForm = document.createElement('form')
        ccForm.method = 'post'
        ccForm.action = data.actionUrL
        ccForm.id = 'ccForm_to_submit'

        // create hidden input
        for (const [key, value] of formData) { // eslint-disable-line
          const ccInput = document.createElement('input')
          ccInput.type = 'hidden'
          ccInput.name = key
          ccInput.value = value
          ccForm.appendChild(ccInput)
        }

        // append this to the body and submit the form.
        document.body.appendChild(ccForm)
        document.getElementById('ccForm_to_submit').submit()
      }
    },
    isAntiFraudInvoiceFn() {
      axios.get(`/api/antifraud/is_anti_fraud_invoice?invoice_id=${this.$route.params.id}`)
        .then(res => {
          if (res.data.isInvoice) {
            const { card } = res.data
            this.isAntiFraudInvoice = true
            if (card) this.antiFraudCardLabel = `${card.card_type} ****** ${card.card_exp}`
            if (card) this.hasSingleCard = true
          }
        })
    },

    async download() {
      this.downloading = true
      const url = `/api/custom-invoices/download/${this.$route.params.id}`
      await axios.get(url, { responseType: 'blob' })
        .then(res => {
          const pdfFilename = this.removeDot(this.pdfFilename)
          const link = document.createElement('a')
          const pdf_url = URL.createObjectURL(res.data)
          link.setAttribute('href', pdf_url)
          link.setAttribute('download', pdfFilename)
          link.style.visibility = 'hidden'
          link.id = 'downloadPDF'
          document.body.appendChild(link)
          document.getElementById(link.id).click()

          // remove to make sure a new csv file will be downloaded and not the previous one
          const element = document.getElementById(link.id)
          element.remove()
        })
      this.downloading = false
    },

    removeDot(str) {
      const str_array = str.split('.')
      let filename = ''
      str_array.forEach(x => {
        filename += x
      })
      return filename
    },

    amountInBTC(valueInEuro, btcRate) {
      if (btcRate > 0) {
        const btc = valueInEuro / btcRate
        const btcArr = btc.toString().split('.')
        const amountInBTC = `${btcArr[0]}.${btcArr[1].slice(0, 8)}`
        return amountInBTC
      }
      return 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-details-logo {
  width: 100%;
  max-width: 25rem;
}

.invoice-to-address {
  max-width: 20rem;
  line-height: 24px;
}

.invoice-period {
  padding: 10px 29px;
  border-top: 1px solid #e7e7e7;
  font-weight: 500;
  font-size: 14px;
  margin-top: -1rem;
}

.paid-style {
  color: #189550;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.customTable{
    width: 100%;
}

.customTable tr th, .customTable tr td {
    border: none;
    text-align: left;
    padding:8px;
    border: 1px solid #cccccc
}
.leftcell{
    width: 180px;
}
.note{
    font-size:medium;
    padding: 15px 0px 5px;
    color:brown
}

.modal .modal-header .close{
  margin: 2px
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .iban_code,
  .btc_address{
    display: block;
    overflow-wrap: break-word;
    width: 200px
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {
  .iban_code,
  .btc_address{
    display: block;
    overflow-wrap: break-word;
    width: 190px
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {
  .iban_code,
  .btc_address{
    display: block;
    overflow-wrap: break-word;
    width: 180px
  }
}

/* smaller phone */
@media only screen and (max-width: 365px)  {
  .iban_code,
  .btc_address{
    display: block;
    overflow-wrap: break-word;
    width: 180px
  }
}
</style>
