<template>
  <div style="text-align:justify;text-indent:30px">
    <p>
      <strong>Billing</strong>
      <br> TrafficManager is billing its customers on a membership basis. The membership is billed in advance, while usage is billed in arrears.
    </p>
    <p>We cannot cancel accounts retroactively. For new customers, your first invoice is due after your 30-day trial. It is prorated to cover the remaining days in the month and to align your billing to a calendar-month cycle. All invoices thereafter are due on the 1st of each month. We bill in whole-month increments only, with the exception of your first invoice or for account reactivation. Customers with Contract: Your contract’s terms supersede these policies. Refer to your contract for details.</p>
    <p>
      <strong>Free Trial</strong>
      <br> Each new TrafficManager customer is eligible for one free trial only, regardless of the number of accounts a customer opens.
    </p>
    <p>
      <strong>Payment Terms</strong>
      <br> Payment is processed when the invoice is generated, and is otherwise due upon receipt. Customers requesting NET payment terms are subject to credit check. We reserve the right to approve or deny any such request at our discretion.
    </p>
    <p>
      <strong>Payment Methods</strong>
      <br> To subscribe to our monthly services, we require a valid credit card that is registered by the payment processor and used to process your monthly invoices. The amounts owed are charged to the card on payment processor's file unless you request, and we approve, a wire transfer payment method. All TrafficManager customers are responsible for any and all fees associated with sending a wire from the originating bank. TrafficManager absorbs the portion of the wire transfer fee charged by its bank or any intermediate bank(s) associated with receipt of the wire payment. The amount owed must arrive at our bank in full. The customer is responsible for any debt resulting from currency exchange and understands that any unpaid balance will be carried over to their next invoice.
    </p>
    <p>
      <strong>Upgrade Policy</strong>
      <br> All customers who signed up for the services online are governed by the online agreement and have the ability to upgrade their account at any time. To do so, go to Account in your TrafficManager Dashboard, available upon Login. Upon upgrading your account, you agree to be charged for the difference between the respective edition membership fees. Upon upgrading, your account is immediately updated to the new overage threshold. Customers on contract can submit an upgrade request by contacting the TrafficManager support.
    </p>
    <p>
      <strong>Downgrade Policy</strong>
      <br> If your account was created via online registration and you do not have a custom contract, you can downgrade your account at any time by contacting the TrafficManager support. Downgrade requests are honored upon our receiving them. Billing is affected on the start of the next billing cycle; we do not prorate accounts or provide refunds for time between downgrade and the start of the next cycle.
    </p>
    <p>
      <strong>Cancellation &amp; Refund Policy</strong>
      <br> All customers who registered for the services online are governed by the online agreement and have the ability to cancel their account at any time. Instructions to do so are in Account, in the TrafficManager Dashboard, available upon Login. To prevent future billing on your account, you must complete your cancellation before the first day of the upcoming month. You may also submit a cancellation request to support@trafficmanager.com. Please note that since you may cancel your account at any time, we do not provide refunds. We bill in arrears for your usage and in advance for your monthly membership fee.
    </p>
    <p>
      <strong>Delivery policy</strong>
      <br> This is a digital service. After the registration is complete and validated, the client receives a login and a password to access the service online via our dashboard located at trafficmanager.com.
    </p>
    <p class="font-italic font-weight-bold mt-5">
      Last edit: Nov 10, 2020
    </p>
  </div>
</template>

<script>
// import {
//   // eslint-disable-next-line no-unused-vars
//   BModal,
// } from 'bootstrap-vue'

export default {
  components: {},
  data() {
    return { }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" >
</style>
